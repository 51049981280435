<template>
  <v-container fluid>
    <v-flex xs12>
      <v-card class="grey lighten-4 elevation-0">
        <v-card-title class="title">
          {{ title }}{{ customer.firstname }} {{ customer.lastname }}
          <v-spacer></v-spacer>
          <v-btn elevation="4" fab small dark class="grey mr-2" @click.native="cancel()">
            <v-icon dark="">mdi-close-circle-outline</v-icon>
          </v-btn>
          &nbsp;
          <v-btn elevation="4" fab small dark class="blue" @click.native="save()">
            <v-icon>mdi-content-save-all</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="loading !== true">
          <v-container fluid grid-list-sm>
            <v-layout row wrap>
              <v-flex md3 sm12>
                <v-img v-if="customer!==null" small max-width="10em" :src="customer.avatar" class="avatar "  :srcset="customer.avatar" />
              </v-flex>
              <v-flex md9 sm12>
                <v-container fluid grid-list-sm>
                  <v-layout row wrap>
                    <v-flex md4 sm12 xs12 class="mx-1 px-0">
                      <v-text-field
                        name="firstname"
                        label="Jméno"
                        hint="Last name is required"
                        value="Input text"
                        v-model="customer.firstname"
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4 sm12 class="mx-1 px-0">
                      <v-text-field
                        name="lastname"
                        label="Příjmení"
                        maxlength="10"
                        hint="Last name is required"
                        value="Input text"
                        v-model="customer.lastname"
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4 sm12 xs12 class="mx-1 px-0">
                      <v-text-field
                        name="email"
                        type="email"
                        label="Email"
                        value="Input text"
                        v-model="customer.email"
                        v-bind:rules="rules.email"
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4 sm12 xs12 class="mx-1 px-0">
                      <v-text-field
                        name="mobile"
                        type="text"
                        label="Telefon"
                        v-model="customer.phone"
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4 sm12 xs12 class="mx-1 px-0">
                      <v-text-field
                        name="street"
                        type="text"
                        label="Ulice"
                        value="Input text"
                        v-model="customer.street"
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4 sm12 xs12 class="mx-1 px-0">
                      <v-text-field
                        name="streetNumber"
                        type="text"
                        label="Číslo popisné"
                        value="Input text"
                        v-model="customer.houseNumber"
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4 sm12 xs12 class="mx-1 px-0">
                      <v-text-field
                        name="city"
                        type="text"
                        label="Město"
                        value="Input text"
                        v-model="customer.city"
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4 sm12 xs12 class="mx-1 px-0">
                      <v-text-field
                        name="psc"
                        type="text"
                        label="PSČ"
                        v-model="customer.zip"
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4 sm12 class="mx-1 px-0">
                      <v-text-field
                        name="workphone"
                        type="text"
                        label="Work Phone"
                        v-model="customer.workphone"
                        class="input-group--focused"
                        required
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-flex>
            </v-layout>
            <v-snackbar
              v-if="loading === false"
              :right="true"
              :timeout="2000"
              :color="mode"
              v-model="snackbar"
            >
              {{ notice }}
              <v-btn dark text @click.native="closeSnackbar">Close</v-btn>
            </v-snackbar>
          </v-container>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-container>
</template>

<script>
import databaseService from '../services/services'

export default {
  name: 'CustomerForm',
  data: () => ({
    title: "",
    rules: "",
    customer: [], 
  }),
  methods: {
    cancel() {
      this.$router.push({ name: "Members" });
    },
    save() {
      let memberData = 
      {
        firstName: "customer.firstname",
        lastName: "customer.lastname",
        email: "customer.email",
        phone: "customer.phone",
        address: {
          streetName: "customer.street",
          streetNumber: "customer.homeNumber",
          city: "customer.city",
          zip: "customer.zip",
        }
      }
      
      databaseService.create("members", memberData );
    }
  },
  created() {
    this.customer = databaseService.getItem("members", this.$route.params.id);
  },
  mounted() {
    if (this.$route.params.id) {
      this.title = "Upravit - ";
      // this.customerAvatar();
    } else this.title = "New Customer";
  }
}
</script>

<style scoped>
.avatar {
  border-radius: 50%;
}
</style>