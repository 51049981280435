import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyCJ-mVDwwbEoscxeJ9BRb3_xPicrKnkhtE",
  authDomain: "vue-fitness-manager.firebaseapp.com",
  projectId: "vue-fitness-manager",
  storageBucket: "vue-fitness-manager.appspot.com",
  messagingSenderId: "524438408509",
  appId: "1:524438408509:web:c9c4f12577260cedc08954"
};

const app = firebase.initializeApp(firebaseConfig);
const db = app.firestore();

export default db;