import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import store from './store'

const firebaseConfig = {
  apiKey: "AIzaSyCJ-mVDwwbEoscxeJ9BRb3_xPicrKnkhtE",
  authDomain: "vue-fitness-manager.firebaseapp.com",
  projectId: "vue-fitness-manager",
  storageBucket: "vue-fitness-manager.appspot.com",
  messagingSenderId: "524438408509",
  appId: "1:524438408509:web:c9c4f12577260cedc08954"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = false

firebase.auth().onAuthStateChanged(user => {
  console.log(user)
  new Vue({
    router,
    vuetify,
    store,
    render: h => h(App)
  }).$mount('#app')
})