import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import router from '../router'

const actions = {
  authAction({commit}) {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        commit("setUser", user);
      } else {
        commit("setUser", null);
      }
    })
  },
  signUpAction({ commit }, payload) {
    firebase.auth()
    .createUserWithEmailAndPassword(payload.email, payload.password)
    .then(response => {
      commit("setUser", response.user);
    })
    .catch(error => {
      commit("setError", error.message);
    });
  },
  signInAction({ commit }, payload) {
    return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => {
      return firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
      .then(() => {
        router.push('/members');
      })
      .catch(error => {
        commit("setError", error.message);
      })
    })
  },
  signOutAction({commit}) {
    firebase.auth().signOut()
    .then(() => {
      commit("setUser", null);
    })
    .catch(error => {
      commit("setError", error.message);
    })
  },
  handleNavigation({commit}, item) {
    commit("setActiveMenuItem", item.title);
    this.$router.push({
      name: item.link
    })
  }
}

export default actions