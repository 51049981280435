import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    default: 'light',
    themes: {
      light: {
        background: '#A6CE39',
        loginButton: '#8F53A1',
      },
    },
  },
});
