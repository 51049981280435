<template>
<v-container fill-height justify-center align-center>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="100"
        />
      </v-col>

      <v-col class="mb-4">
        <h2>Pro vstup do systému se prosím přihlaste</h2>
        <p class="subheading font-weight-regular">
        V případě problémů kontaktuje prosím Vašeho správce
        </p>
      </v-col>
    </v-row>
    <v-flex xs12 sm6 md4 lg3>
          <form @submit.prevent="submit">
            <v-layout row wrap>
              <v-flex xs12 md4>
                <v-subheader>Email</v-subheader>
              </v-flex>
              <v-flex xs12 md8>
                <v-text-field
                  class="input-group--focused mr-2"
                  name="email"
                  v-model="email"
                  label="email"
                  value="Input text"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 md4>
                <v-subheader>Heslo</v-subheader>
              </v-flex>
              <v-flex xs12 md8>
                <v-text-field
                  class="input-group--focused mr-2"
                  name="password"
                  type="password"
                  v-model="password"
                  label="heslo"
                  value="Input text"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-card-actions>
              <v-btn elevation="4" type="submit" :style="{background: $vuetify.theme.themes['light'].loginButton}" >Přihlásit se</v-btn>
            </v-card-actions>
          </form>
      </v-flex>
  </v-container>
</template>
<script>
import { mapActions } from "vuex"

  export default {
    name: 'Login',
    data() {
      return {
        email: null,
        password: null,
        firebaseError: ''
      }
    },
    methods: {
      ...mapActions(["signInAction"]),
      submit() {
        this.signInAction({ email: this.email, password: this.password });
        }
      }
    }
</script>