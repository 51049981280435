import db from "../firebase/firebase"

class databaseService {

  getAll(collection) {
    let allItems = [];

    db.collection(collection).get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
      allItems.push(doc.data());  
      });
    });
    return allItems
  }
  getItem(collection, id) {
    let item = {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      street: "",
      houseNumber: "",
      city: "",
      zip: "",
    };

    db.collection(collection).where("memberID", "==", id).get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        //item.push(doc.data());
        item.firstname = doc.get("memeberName");
        item.lastname = doc.get("memberSurname");
        item.email = doc.get("memberEmail");
        item.phone = doc.get("memberPhone");
        item.street = doc.get("memberAddressStreet");
        item.houseNumber = doc.get("memberAddressHouseNumber");
        item.city = doc.get("memberAddressTown");
        item.zip = doc.get("memberAddressZip");
      });
    });
    return item
  }

  create(collection, data) {
    return db.collection(collection).add(data);
  }

  update(collection, id, data) {
    return db.collection(collection).doc(id).update(data);
  }

  delete(collection, id) {
    return db.collection(collection).doc(id).delete();
  }
}

export default new databaseService();