<template>
  <div>
    <h1>Přehled členů</h1>
    <Table
      :headers="headers"
      :items="items"
      :pagination="pagination"
      @edit="edit"
      @remove="remove"
    >  
    </Table>
  </div>  
</template>

<script>
import databaseService from '../services/services'
import Table from '../components/Table.vue'

  export default {
    name: 'Members',
    components: {
      Table
    },
    data() {
      return {
        headers: [
        {
          text: "Členství",
          value: "sub",
          sortable: true
        },
        {
          text: "",
          value: "avatar",
          sortable: false
        },
        {
          text: "Jméno",
          left: true,
          value: "memeberName"
        },
        { text: "Příjmení", value: "memberSurname" },
        { text: "Email", value: "memberEmail" },
        { text: "Telefon", value: "memberPhone" },
        { text: "Typ členství", value: "rewards" },
        { text: "Členství", value: "membership" },
        { text: "Kód", value: "memberBarcode" },
        { text: "ID", value: "memberID" },
        { text: "", value: "actions", sortable: false }
        ],
        items: [],
        pagination: []
      }
    },
    methods: {
      edit(item) {
        this.$router.push(`member/${item.memberID}`);
      },
      remove(item) {
        this.itemId = item.id;
        this.dialog = true;
      },
      getMemberList(collection){
        let memberList = databaseService.getAll(collection);
        this.items = memberList;
      }
    },
    mounted() {
      this.getMemberList('members');
    }
  }
</script>