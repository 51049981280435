<template>
  <v-app>
    
    <template v-if="!isUserAuth">
      <v-main :style="{ background: $vuetify.theme.themes.light.background}">
        <router-view></router-view>
      </v-main>
    </template>
   
    <template v-if="isUserAuth">
      <v-navigation-drawer class="blue lighten-5" mini-variant-width="70" width="250" light :mini-variant.sync="mini" v-model="drawer" app>
        <v-list class="pa-0">
          <v-list-item tag="div">
            <v-list-item-action>
              <v-img max-width="2.5em" class="avatar" src="@/assets/avatar0.png" />
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ getUser.email }}</v-list-item-title>
            </v-list-item-content>
            <!-- <v-spacer></v-spacer> -->
            <v-list-item-action style="max-width:1em">
              <v-menu bottom right offset-y origin="bottom right" transition="v-slide-y-transition">
                <template v-slot:activator="{ on }">
                  <v-btn icon small light slot="activator" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <!-- User Menu -->
                <v-list>
                  <v-list-item v-for="item in userMenu" :key="item.title" value="true" @click="handleUserActions(item)" router>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <!-- User Menu end -->
              </v-menu>
            </v-list-item-action>
            <v-list-item-action style="max-width:1em">
              <v-btn icon small @click.native.stop="mini = !mini">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <!-- Left Nav Bar -->
        <v-list>
          <v-list-item v-for="item in appMenu" :key="item.title" @click="handleNavigtiion(item)" router>
            <v-list-item-action class="pr-1 pl-2 mr-1">
              <v-icon :class="activeMenuItem ? 'blue--text' : ''" :title="item.title">
                {{ item.icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content :class="activeMenuItem ? 'blue--text' : ''">
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <!-- Left Naw Bar end -->
      </v-navigation-drawer>
      <v-app-bar app elevation=1>
        <v-app-bar-nav-icon @click.native.stop="drawer = !drawer" light></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <div class="text-xs-center pr-3 mt-2">
          <v-badge left="">
            <span slot="badge">6</span>
            <v-icon large color="grey lighten-1">mdi-bell-outline</v-icon>
          </v-badge>

          <v-badge color="red">
            <span slot="badge">!</span>
            <v-icon large color="grey">mdi-email-outline</v-icon>
          </v-badge>
        </div>
      </v-app-bar>
      <v-main>
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-main>
      <v-footer :inset="true" style="justify-content:center; text-align: center" app>
        <span>&copy; Fitness Manager 2021</span>
      </v-footer>
    </template>

  </v-app>
</template>
<script>
import { mapGetters, mapActions } from "vuex"

export default {
  name: 'App',
  components: { },
  data: () => ({
    dialog: false,
    mini: false,
    dialogText: '',
    dialogTitle: '',
    isRootComponent: true,
    drawer: window.innerWidth > 960,
    fixed: false,

    miniVariant: false,
    right: true,
    rightDrawer: false,
    menuItem: '',

    userMenu: [
    {
      icon: 'bubble_chart',
      title: 'Odhlásit se',
      link: 'login'
    },
    {
      icon: 'bubble_chart',
      title: 'Změnit heslo',
      link: 'changepassword'
    }
    ],
    appMenu: [
    {
      icon: 'mdi-view-dashboard',
      title: 'Dashboard',
      vertical: 'Dashboard',
      link: 'Dashboard'
    },
    {
      icon: 'mdi-account-group',
      title: 'Přehled členů',
      vertical: 'Přehled členů',
      link: 'Members'
    },
    {
      icon: 'mdi-point-of-sale',
      title: 'Platby/Fakturace',
      vertical: 'Platby/Fakturace',
      link: 'Payments'
    },
    {
      icon: 'mdi-book-multiple',
      title: 'Splátky',
      vertical: 'Splátky',
      link: 'Debts'
    },
    {
      icon: 'mdi-information-outline',
      title: 'Smlouvy',
      vertical: 'Smlouvy',
      link: 'Contracts'
    }
    ]
  }),
  mounted() {
    this.authAction();
  },
  computed: {
    ...mapGetters(["getUser", "isUserAuth"])
  },
  methods: {
    ...mapActions(["authAction", "signOutAction"]),

    activeMenuItem() {
      return this.menuItem;
    },

    handleNavigtiion(item) {
      this.menuItem = item.title;
      this.$router.push({
        name: item.link
      });
    },

    async handleUserActions(item) {
      this.menuItem = item.title;
      if (item.title === 'Odhlásit se') {
        await this.signOutAction();
      }
      this.$router.push('/');
    }
  }
}
</script>