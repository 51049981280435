import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import Login from '../pages/Login.vue'
import Dashboard from '../pages/Dashboard.vue'
import Members from '../pages/Members.vue'
import MemberDetail from '../pages/memberDetail.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      authRequired: true,
    }
  },
  {
    path: '/members',
    name: 'Members',
    component: Members,
    meta: {
      authRequired: true,
    }
  },
  {
    path: "/member/:id",
    component: MemberDetail,
    name: "MemberDetail",
    meta: {
      authRequired: true,
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  let user = firebase.auth().currentUser;
    if (to.matched.some(record => record.meta.authRequired)) {
        if (user) {
            next();
        } else {
            alert('You must be logged in to see this page');
            next({
                path: '/',
            });
        }
    } else {
        next();
    }
});

export default router
